import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const ExistingUserRegisterForAnAssociation = () => {
  return (
    <Layout>
      <Seo
        title="How Can an Existing User Register for an Association?"
        description={`In association registration, users are given easy instructions on how to achieve verification status after submitting complete details through the Synkli App.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`existing-user-register-for-an-association`} />
    </Layout>
  )
}

export default ExistingUserRegisterForAnAssociation
